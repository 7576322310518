/**
 * @jsxRuntime classic
 * @jsx jsx
 */

import { lazy, Suspense } from 'react';

import { jsx } from '@compiled/react';
import { ErrorBoundary } from 'react-error-boundary';

import { type ModalDialogContentProps } from '../types';

import { ErrorState } from './error-state';
import { LoadingState } from './loading-state';
import { withModalDialog } from './modal-dialog';

const LazyGlobalInviteModalDialogContentWithRelayProvider = lazy(() =>
	import(
		/* webpackChunkName: "@atlaskit-internal_global-invite--modal-dialog-content-with-relay-provider" */
		'./modal-dialog-content/async'
	).then((module) => ({
		default: module.ModalDialogContentWithRelayProvider,
	})),
);

function AsyncGlobalInviteModalDialogContentWithRelayProvider(props: ModalDialogContentProps) {
	return (
		<ErrorBoundary fallback={<ErrorState />}>
			<Suspense fallback={<LoadingState />}>
				<LazyGlobalInviteModalDialogContentWithRelayProvider {...props} />
			</Suspense>
		</ErrorBoundary>
	);
}

export const GlobalInviteWithLazyLoadedRelayProvider = withModalDialog(
	AsyncGlobalInviteModalDialogContentWithRelayProvider,
);
