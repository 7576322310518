import React, { useState } from 'react';
import { Coordination } from '@atlassiansox/engagekit-ts';

import { LoadableLazy } from '@confluence/loadable';
import { useCoordinationClient } from '@confluence/engagement-provider';
import { fg } from '@confluence/feature-gating';
import { useSessionData } from '@confluence/session-data';
import { ErrorDisplay } from '@confluence/error-boundary';

import { setHasDismissedNav4Modal, useHasDismissedNav4Modal } from './useHasDismissedNav4Modal';

const Nav4OnboardingModalLoader = LoadableLazy({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-Nav4OnboardingModal" */ './Nav4OnboardingModal'))
			.Nav4OnboardingModal,
});

export const CoordinatedNav4OnboardingModal = () => {
	const { userId } = useSessionData();
	const coordinationClient = useCoordinationClient(false);
	const { hasDismissedNav4Modal, loading, error } = useHasDismissedNav4Modal();
	const onboardingMessageId = getOnboardingMessageId();

	const [showModal, setShowModal] = useState(true);

	const onClose = () => {
		setShowModal(false);
		void coordinationClient.stop(onboardingMessageId);
		userId && setHasDismissedNav4Modal(userId);
	};

	if (!showModal || hasDismissedNav4Modal || loading || !fg('confluence_nav_4_changeboarding')) {
		return null;
	}

	return (
		<>
			<Coordination client={coordinationClient} messageId={onboardingMessageId}>
				<Nav4OnboardingModalLoader onClose={onClose} />
			</Coordination>
			{error && <ErrorDisplay error={error} />}
		</>
	);
};

const getOnboardingMessageId = () =>
	fg('confluence_nav_4_changeboarding_message')
		? 'cc-nav-4-onboarding-prod'
		: 'cc-nav-4-onboarding';
