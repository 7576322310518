import { useIsDarkMode } from './useIsDarkMode';
import { getCustomTheme } from './getCustomTheme';

export const useCustomTheme = (
	backgroundColorRaw: string | null | undefined,
	highlightColorRaw: string | null | undefined,
) => {
	const isDarkMode = useIsDarkMode();
	return getCustomTheme({ backgroundColorRaw, highlightColorRaw, isDarkMode, isNav4Enabled: true });
};
