import { LoadablePaint } from '@confluence/loadable';

export const HubSettingsNavigation = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-HubSettingsNavigation" */ './HubSettingsNavigation'
			)
		).HubSettingsNavigation,
	name: 'HubSettingsNavigation',
});
