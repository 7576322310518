import React from 'react';
import { useQuery } from 'react-apollo';

import UFOSegment from '@atlaskit/react-ufo/segment';

import { TopBar as AkTopBar } from '@atlassian/navigation-system/layout/top-bar';

import { TopNavigationLoader } from '@confluence/app-navigation/entry-points/TopNavigationLoader';
import { useCustomTheme } from '@confluence/app-navigation/entry-points/useCustomTheme';
import { ErrorDisplay } from '@confluence/error-boundary';

import { LazyImpersonationFlag } from './impersonation/LazyImpersonationFlag';
import type { ThemeQuery as ThemeQueryData } from './__types__/ThemeQuery';
import { ThemeQuery } from './ThemeQuery.graphql';

type TopBarProps = {
	defaultCollapsed: boolean;
};

export const TopBar = ({ defaultCollapsed }: TopBarProps) => {
	const { data, error } = useQuery<ThemeQueryData>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		ThemeQuery,
	);

	const horizontalHeader = data?.lookAndFeel?.custom?.horizontalHeader;
	const customTheme = useCustomTheme(
		horizontalHeader?.backgroundColor,
		horizontalHeader?.primaryNavigation?.highlightColor,
	);

	return (
		<AkTopBar testId="grid-topNav" UNSAFE_theme={customTheme}>
			<UFOSegment name="top-nav">
				<TopNavigationLoader defaultCollapsed={defaultCollapsed} />
				{/* FIXME: Why is this in TopBar or @confluence/page-layout at all? Can it be moved to @confluence/global-components? */}
				<LazyImpersonationFlag />
			</UFOSegment>
			{error && <ErrorDisplay error={error} />}
		</AkTopBar>
	);
};
