import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { IconButton } from '@atlaskit/button/new';
import MegaphoneIcon from '@atlaskit/icon/core/megaphone';
import Tooltip from '@atlaskit/tooltip';

import { MenuButtonItem } from '@atlassian/navigation-system/side-nav/menu-button-item';

import { fg } from '@confluence/feature-gating';
import { useNav4FeedbackCollectorActions } from '@confluence/nav4-feedback';

export const Nav4GiveFeedbackButton = () => {
	const intl = useIntl();
	const { openFeedbackCollector } = useNav4FeedbackCollectorActions();

	return (
		<Tooltip content={intl.formatMessage(i18n.shareFeedbackButtonText)}>
			{fg('confluence_nav4_global-invite') ? (
				<IconButton
					icon={MegaphoneIcon}
					onClick={openFeedbackCollector}
					label={intl.formatMessage(i18n.shareFeedbackButtonText)}
				/>
			) : (
				<MenuButtonItem
					elemBefore={<MegaphoneIcon label="" color="currentColor" />}
					onClick={openFeedbackCollector}
				>
					{intl.formatMessage(i18n.shareFeedbackButtonText)}
				</MenuButtonItem>
			)}
		</Tooltip>
	);
};

const i18n = defineMessages({
	shareFeedbackButtonText: {
		id: 'page-layout.nav4.feedback-collector.share-feedback-button.text',
		defaultMessage: 'Give feedback on the new navigation',
		description:
			'Text for button to open the feedback collector modal where user can share feedback',
	},
});
